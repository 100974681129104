html, body, #root {
  height: 100%;
}

.main {
  height: 100%;
  text-align: center;
}

.logo {
  width: 50%;
  pointer-events: none;
}

.centre {
  height: 100%;
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: white;
}